/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

* {
  padding: 0;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --toastify-font-family: inherit;
  --toastify-color-light: #fff;
  --toastify-text-color-light: inherit;
}

[data-theme="dark"] {
  --toastify-color-light: #0e1726;
  --toastify-text-color-light: inherit;
}

html {
  box-sizing: border-box;
  min-height: 100%;
}

html,
body {
  margin: 0;
  min-height: 100%;
  max-width: 100vw;
  position: relative;
  word-break: break-word;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  font-family: "Nunito", sans-serif;
  overflow-x: hidden;
}

.app {
  width: 100%;
}

.sidebar-list.closed::-webkit-scrollbar {
  width: 0;
}

.sidebar-list::-webkit-scrollbar {
  width: 5px;
}
.sidebar-list::-webkit-scrollbar-track {
  background: transparent;
}
.sidebar-list::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 999px;
}
.sidebar-list::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.sidebar-list:hover::-webkit-scrollbar-thumb {
  background: #888;
}
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #e0e0e0;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.wrapWord {
  white-space: normal !important;
  overflow: hidden;
  text-overflow: clip;
}

.wrap-header {
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* This ensures your container takes up the full viewport height */
}

.dataAboveGrid {
  flex: 1; /* Takes up 1/4 of the space */
  overflow: auto; /* Just in case your content is longer than the available space */
}

.dataGridContainer {
  flex: 3; /* Takes up the remaining 3/4 of the space */
}

.login-section {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 4rem;
}

.Toastify__toast-container {
  color: inherit;
}

.Toastify__toast-body {
  align-items: flex-start;
}

.Toastify__toast-title {
  font-size: 18px;
  font-weight: 700;
}

.Toastify__toast-icon {
  margin-top: 3px;
}

.Toastify__close-button--light {
  color: inherit;
}

.Toastify__toast-message,
.Toastify__toast-description {
  margin-top: 5px;
}
